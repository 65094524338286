<template>
  <div class="ship-via-card">
    <div class="card-left">
      <b-icon-box></b-icon-box>
    </div>
    <div class="card-mid">
      <div class="card-title">{{ title }}</div>
      <b-form-input
       v-if="isEditable"
                id="default-ship-via"
                placeholder="ex: UPS"
                maxlength=254
                v-model="shipViaValue.name">
    </b-form-input>
    <div v-else class="card-value">{{ shipViaValue.name }}</div>
    </div>
    <div class="card-right">
      <div class="edit-button-wrapper">
        <feather class="edit-button float-right" :type="editionIcon" v-on:click="toggleEdit"> </feather>
      </div>
      <b-spinner label="Loading..." variant="secondary" v-if="loading"></b-spinner>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'card',
  props: {
    title: String,
    shipVia: {
      type: Object,
    },
  },
  data() {
    return {
      isEditable: false,
      loading: false,
      sv: '',
    };
  },
  methods: {
    toggleEdit() {
      if (this.isEditable) {
        this.loading = true;
        this.saveDefaultShipVia();
      } else {
        this.isEditable = !this.isEditable;
      }
    },
    saveDefaultShipVia() {
      axios
        .put('/v1/shipvia/default', { Name: this.shipViaValue.name, Type: this.shipVia.type, Unit: this.shipVia.unit })
        .then(() => {
          this.$noty.success('Default saved with success');
        })
        .catch(() => {
          this.$noty.error('Error saving default ship via');
        })
        .finally(() => {
          this.isEditable = !this.isEditable;
          this.loading = false;
        });
    },
  },
  computed: {
    editionIcon() {
      return this.isEditable ? 'check' : 'edit-2';
    },
    shipViaValue: {
      get() {
        return this.sv !== '' ? this.sv : this.shipVia;
      },
      set(newValue) {
        this.sv = newValue;
      },
    },
  },
};
</script>
