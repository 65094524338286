<template>
  <div>
    <div class="bs-callout bs-callout-info">
      <p>Configure shipping by type and regions. <b>Whenever the setting is missing, the default value will be applied.</b></p>
    </div>
    <div class="table-responsive">
      <table class="table border">
        <thead>
        <tr>
          <th scope="col" v-for="(header, index) in headers" :key="index">{{ header }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="region in regions" :key="region.id">
          <td class="col-2">
            <input class="form-control" type="text" :value="region.name" :readonly="true"/>
          </td>
          <td class="col-1">
            <input class="form-control" type="text" :value="region.code" :readonly="true"/>
          </td>
          <td class="col-3">
            <input class="form-control" type="text"
                   :value="region.methods.auto ? region.methods.auto.ship_via : ''"
                   @input="event => onInput(event.target.value,'auto', region)"
                   :readonly="false"/>
          </td>
          <td class="col-3">
            <input class="form-control" type="text"
                   :value="region.methods.manual ? region.methods.manual.ship_via : ''"
                   @input="event => onInput(event.target.value,'manual', region)"
                   :readonly="false"/>
          </td>
          <td class="col-3">
            <input class="form-control" type="text"
                   :value="region.methods.late_onboarding ? region.methods.late_onboarding.ship_via : ''"
                   @input="event => onInput(event.target.value,'late_onboarding', region)"
                   :readonly="false"/>
          </td>
        </tr>
        </tbody>
      </table>
      <FloatingButtons :buttons="buttons" v-on:floating-button-click="onSave"></FloatingButtons>
    </div>
  </div>
</template>

<script>
import FloatingButtons from '@/components/FloatingButtons.vue';

export default {
  name: 'Ship-Via-Regions',
  components: { FloatingButtons },
  data() {
    return {
      headers: [ 'State', 'Code', 'Auto', 'Manual', 'Late' ],
      regions: [],
      updatedMethods: {},
      totalChanges: 0,
    };
  },
  props: {},
  async beforeMount() {
    await this.loadShipViaRegionsMethods();
  },
  computed: {
    buttons() {
      return {
        save: {
          icon: 'save',
          text: `Save (${this.totalChanges})`,
          color: 'primary',
        },
      };
    },
  },
  methods: {
    async loadShipViaRegionsMethods() {
      const data = await this.$store.dispatch('OrderManagement/getShipViasRegionsMethods');
      if (data) {
        this.regions = data.regions;
      }
    },
    setTotalChanges() {
      this.totalChanges = Object.keys(this.updatedMethods).length;
    },
    clearChanges() {
      this.updatedMethods = {};
      this.setTotalChanges();
    },
    onInput(value, method, region) {
      if (!region.methods[method]) {
        region.methods[method] = {
          region_id: region.id,
          method,
        };
      }

      region.methods[method].ship_via = value;

      const key = `${region.code}_${method}`;
      this.updatedMethods[key] = region.methods[method];
      this.setTotalChanges();
    },
    async onSave() {
      if (Object.keys(this.updatedMethods).length === 0) {
        return;
      }

      const req = [];
      Object.values(this.updatedMethods).forEach(item => req.push(item));

      const res = await this.$store.dispatch('OrderManagement/updateShipViasRegionsMethods', req);
      if (res) {
        this.clearChanges();
        await this.loadShipViaRegionsMethods();
      }
    },
  },
};
</script>
