<template>
  <div class="p-3">
    <header>
      <h3>Ship Via Configurations</h3>
    </header>
    <div class="mt-4 mb-4">
      <default-ship-via></default-ship-via>
    </div>
    <div class="pt-5">
      <b-tabs content-class="mt-4">
        <b-tab title="Regions" active>
            <ship-via-regions></ship-via-regions>
        </b-tab>
      <b-tab title="Exceptions">
        <ship-via-exceptions :tagModule="'SHIPVIA_EXCEPTIONS'"></ship-via-exceptions>
      </b-tab>
    </b-tabs>
    </div>
  </div>
</template>

<script>
import DefaultShipVia from '../../../components/OrderManagement/ShipVia/DefaultShipVia.vue';
import exceptions from '../../../components/OrderManagement/SkuConfigs/ClientExceptions.vue';
import ShipViaRegions from '../../../components/OrderManagement/ShipVia/Regions.vue';

export default {
  name: 'ShipVia.vue',
  components: {
    'default-ship-via': DefaultShipVia,
    'ship-via-exceptions': exceptions,
    'ship-via-regions': ShipViaRegions,
  },
};
</script>

<style lang="scss">
@import '../../../styles/OrderManagement.scss';
</style>
