<template>
  <div>
    <b-row>
      <b-col>
        <div class="bs-callout bs-callout-info">
          <h6>Defaults</h6>
          <p>Configure the default type of shipment that will be applied to all orders created <b>whenever the region
            setting is missing</b>.
          </p>
        </div>
      </b-col>
    </b-row>
    <div class="pt-5">
      <b-tabs>
        <b-tab title="DPT" active>
          <b-row class="pt-3">
            <b-col v-for="(shipVia, index) in shipViasByUnit(tabs.dpt)" :key="index" class="cards-holder">
              <card :title="mapShipViaTitle(shipVia.type)" :shipVia="shipVia"></card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Bloom">
          <b-row class="pt-3">
            <b-col v-for="(shipVia, index) in shipViasByUnit(tabs.bloom)" :key="index" class="cards-holder">
              <card :title="mapShipViaTitle(shipVia.type)" :shipVia="shipVia"></card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Move">
          <b-row class="pt-3">
            <b-col v-for="(shipVia, index) in shipViasByUnit(tabs.move)" :key="index" class="cards-holder">
              <card :title="mapShipViaTitle(shipVia.type)" :shipVia="shipVia"></card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { UNIT_DPT, UNIT_BLOOM, UNIT_MOVE } from '@/scripts/constants';
import Card from './ShipViaCard.vue';

const shipViaTypesMapping = {
  auto: 'Auto Ship Via',
  manual: 'Manual Ship Via',
  late_onboarding: 'Late OB Ship Via',
};

export default {
  name: 'default-ship-vias',
  async created() {
    await this.loadDefaultShipVias();
  },
  data() {
    return {
      shipVias: [],
      isHovered: false,
      tabs: {
        dpt: UNIT_DPT,
        bloom: UNIT_BLOOM,
        move: UNIT_MOVE,
      },
    };
  },
  components: {
    card: Card,
  },
  methods: {
    async loadDefaultShipVias() {
      await axios
        .get('v2/shipvias', { params: { is_default: 1 } })
        .then(data => {
          this.shipVias = data.data.ship_vias;
        })
        .catch(() => {
          this.$noty.error('Unable to load default ship via');
        });
    },
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
    mapShipViaTitle(shipViaType) {
      return shipViaTypesMapping[shipViaType] ? shipViaTypesMapping[shipViaType] : shipViaType;
    },
    shipViasByUnit(unit) {
      return this.shipVias.filter(shipVia => shipVia.unit === unit);
    },
  },
};
</script>
<style lang="scss">
@import '../../../styles/OrderManagement.scss';
</style>
